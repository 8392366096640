<template>
  <header>
    <!-- Topbar -->
    <div class="top">
      <div class="container">
        <div class="row d-flex align-items-center">
          <div class="col" v-if="info.fb != '0' || info.tw != '0' || info.linkedin != '0' || info.pinterest != '0' || info.instagram != '0' || info.youtube != '0'">
            <p
              class="social d-flex"
            >
              <a :href="info.fb" target="_blank" 
                ><span class="icon-facebook"></span
              ></a>
              <a :href="info.tw" target="_blank"
                ><span class="icon-twitter"></span
              ></a>
              <a :href="info.linkedin" target="_blank"
                ><span class="icon-linkedin"></span
              ></a>
              <a :href="info.pinterest" target="_blank"
                ><span class="icon-pinterest"></span
              ></a>
              <a :href="info.instagram" target="_blank"
                ><span class="icon-instagram"></span
              ></a>
              <a :href="info.youtube" target="_blank"
                ><span class="icon-youtube-play"></span
              ></a>
            </p>
          </div>
          <div class="col d-flex justify-content-end" v-if="info.phone !=0">
            <p class="num"><span class="icon-phone"></span> {{ info.phone }}</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Header Container
	================================================== -->
    <nav
      class="navbar navbar-expand-lg navbar-dark ftco_navbar ftco-navbar-light sleep"
      id="ftco-navbar"
    >
      <div class="container">
        <div class="col-8 col-md-8 col-lg-4">
          <router-link to="/">
            <img
            v-if="info"
            class="header-logo m-1"
            :src="info.logoClean"
            alt="logo_web"
          />
          <h4 v-else>{{ info.companyName }}</h4>
          </router-link>
        </div>
        <button
          class="navbar-toggler collapsed"
          type="button"
          data-toggle="collapse"
          data-target="#ftco-nav"
          aria-controls="ftco-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="oi oi-menu"></span>
        </button>

        <div class="collapse navbar-collapse" id="ftco-nav">
          <ul class="navbar-nav ml-auto">
            <li class="nav-item">
              <router-link class="nav-link" to="/">{{ $t("layout.nav.home") }}</router-link>
            </li>
           
            <li class="nav-item">
              <router-link class="nav-link" to="/propiedades"
                >{{ $t("layout.nav.properties") }}</router-link
              >
            </li>
             <li class="nav-item">
              <router-link class="nav-link" to="/nosotros"
                >{{ $t("layout.nav.about.title") }}</router-link
              >
            </li>
            <!-- <li class="nav-item">
              <router-link class="nav-link" to="/desarrollos"
                >{{ $t("layout.nav.developments") }}</router-link
              >
            </li> -->
            <li class="nav-item">
              <router-link class="nav-link" to="/equipo">{{ $t("layout.nav.agents") }}</router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link" to="/contacto"
                >{{ $t("layout.nav.contact") }}</router-link
              >
            </li>
            <li class="nav-item">
              <LocaleSwitcher />
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>

  <!-- Header Container / End 
================================================== -->
</template>


<script>
import { mapGetters } from 'vuex';
import LocaleSwitcher from "@/components/layout/LocaleSwitcher.vue";
export default {
  components:{
    LocaleSwitcher
  },
    computed:{
        ...mapGetters({
            info:'_getInfoCompany'
        }),
    },
}
</script>

<style scoped>
.header-logo {
  width: 10vw !important;
}
@media only screen and (max-width: 991px) {
  .header-logo {
    width: 20%;
  }
}
</style>